/**
 * Bookmarked content.
 */
import { apiGet } from '@/util/api';
import Icon from '@/icons/Icon.vue';
import ContentList from '@/components/ContentList.vue';

export default {
  name: 'Bookmarks',
  components: {
    Icon,
    ContentList,
  },
  data: () => ({
    loading: true,
    contents: [],

    surveys: [],
    signedUrls: [],
    categories: ['Funny', 'Useful', 'Beautiful', 'Inspiring'],
    category: null,
    countries: [],
    country: null,
    genres: [],
    genre: null,
    showBookmarked: false,
    series: null,
    firstLoad: false,

    // Pagination configs
    currentPage: 1,
    perPage: 12,
    rows: 0,
  }),
  async created() {
    await this.getCountries();
    this.firstLoad = true;
    await this.getContents();
    this.loading = false;
  },
  watch: {
    currentPage: async function() {
      this.loading = true;
      await this.getContents();
      this.loading = false;
    },
    category: async function() {
      this.loading = true;
      this.currentPage = 1;
      await this.getGenres();
      await this.getContents();
      this.loading = false;
    },
    genre: async function() {
      this.loading = true;
      this.currentPage = 1;
      await this.getContents();
      this.loading = false;
    },
    country: async function() {
      this.loading = true;
      this.currentPage = 1;
      await this.getContents();
      this.loading = false;
    },
  },
  methods: {
    async getContents() {
      this.loading = true;
      try {
        let query = `?skip=${this.perPage * (this.currentPage - 1)}&take=${
          this.perPage
        }&orderBySort=created&orderByOrder=DESC&bookmarked=true`;

        if (this.category) {
          query += `&category=${this.category}`;
        }

        if (this.genre) {
          query += `&genre=${this.genre}`;
        }

        if (this.country) {
          query += `&country=${this.country}`;
        }

        this.contents = (await apiGet(`/content${query}`)).map(content => {
          const cdate = content.created
            .substr(0, content.created.indexOf('T'))
            .split('-');
          content.created = `${cdate[2]}/${cdate[1]}/${cdate[0]}`;
          content.showBookmark = true;
          return content;
        });
        this.rows = await apiGet(`/content/count${query}`);
      } catch (e) {
        this.$bvToast.toast(this.$t('inspiration_page_error_get_content'), {
          title: this.$t('common_error_title'),
          variant: 'danger',
        });
      }
    },
    /**
     * Get the list of countries which the user has access to for loading into the content filter.
     */
    async getCountries() {
      this.loading = true;
      apiGet('/tag/countries?orderBySort=name&orderByOrder=DESC')
        .then(countries => {
          this.countries = countries.map(tag => ({
            text: tag.name,
            value: tag.name.toLowerCase(),
          }));
        })
        .catch(() => {
          this.$bvToast.toast(this.$t('common_error_body'), {
            title: this.$t('common_error_title'),
            variant: 'danger',
          });
        });
    },
    /**
     * Get the list of genres in the current category to load into the content filter.
     */
    async getGenres() {
      this.loading = true;
      if (!this.category) return null;
      apiGet(
        `/tag?category=GENRE&tagCategory=${this.category}&orderBySort=name&orderByOrder=ASC`,
      )
        .then(genres => {
          this.genres = genres.map(tag => ({
            text: tag.name,
            value: tag.name.toLowerCase(),
          }));
        })
        .catch(() => {
          this.$bvToast.toast(this.$t('common_error_body'), {
            title: this.$t('common_error_title'),
            variant: 'danger',
          });
        });
    },
  },
};
